import React, { Fragment } from "react"
import { Page, Seo } from "gatsby-theme-portfolio-minimal"
import { Section } from "gatsby-theme-portfolio-minimal/src/components/Section"

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component"
import "react-vertical-timeline-component/style.min.css"
import "../css/work.css"
import positionIcon from "../icons/position.svg"
import projectIcon from "../icons/project.svg"

import map from "lodash/map"
import get from "lodash/get"
import { analyze } from "../utils/work"

const { work } = analyze()

export default function WorkPage() {
  return (
    <>
      <Seo title="Work" useTitleTemplate={true} noIndex={true} />
      <Page>
        <Section>
          <h1 id="top" style={{ textAlign: "center", marginBottom: "50px" }}>
            Selected Work & Positions
          </h1>
          <p>
            Having been freelancing for many years, I've worked on projects for
            an array of interesting clients{" "}
            <em>
              (both as direct clients/employers, and indirectly as sub
              contractor)
            </em>
            .
          </p>
          <p>
            These include <strong>Philippe Starck</strong> (
            <a href="/work#yoo.com-2013">link</a>), <strong>Danfoss</strong> (
            <a href="/work#danfoss-2017">link</a>), <strong>Thomas Cook</strong>{" "}
            (<a href="/work#airshoppen-2011">link</a>), the{" "}
            <strong>Danish Royal Collections</strong> (links:{" "}
            <a href="/work#dkks-2009">#1</a> <a href="/work#dkks-2014">#2</a>
            ), <strong>Visit Denmark</strong> (links:{" "}
            <a href="/work#visitdenmark-2017">#1</a>{" "}
            <a href="/work#visitedenmark-2016">#2</a>),{" "}
            <strong>Novo Nordisk</strong> (<a href="/work#novo2017">link</a>),{" "}
            <strong>Greenpeace</strong> (
            <a href="/work#into-eternity-greenpeace-2012">link</a>),{" "}
            <strong>Fritz Hansen</strong> (links:{" "}
            <a href="/work#nordic-aesthetics-2013">#1</a>{" "}
            <a href="/work#milan-fair-2012">#2</a>),{" "}
            <strong>Paradise Hotel on Danish TV3</strong> (
            <a href="/work#tv3-2010">link</a>),{" "}
            <strong>Rezidor SAS Cornerstone</strong> (
            <a href="/work#cornerstone-2006">link</a>),{" "}
            <strong>a large European car company</strong> (
            <a href="/work#jochen-2017">link</a>),{" "}
            <strong>Copenhagen University</strong> (links:{" "}
            <a href="/work#klimakonsulenten-2013">#1</a>{" "}
            <a href="/work#cith-2009">#2</a>),{" "}
            <strong>Canadian Partnership against Cancer</strong> (
            <a href="/work#cpac2016">link</a>), <strong>Norse Projects</strong>{" "}
            (<a href="/work#norse-2017">link</a>),{" "}
            <strong>International Health Insurance</strong> (
            <a href="/work#ihi-2004">link</a>),{" "}
            <strong>Grænseforeningen</strong> (
            <a href="/work#graenseforeningen-2018">link</a>),{" "}
            <strong>the Danish ADHD Society</strong> (
            <a href="/work#vild-stille-2014">link</a>),{" "}
            <strong>Schuler Group</strong> (
            <a href="/work#schuler-2014">link</a>),{" "}
            <strong>Danmarks Underholdningsorkester</strong> (
            <a href="/work#underholdningsorkester-2017">link</a>), <br />
            ...and of course my current employer, one of the largest banks in
            Denmark, <strong>Danske Bank</strong> (links:{" "}
            <a href="/work#danskebank-2020">#1</a>{" "}
            <a href="/work#danskebank-2019-2">#2</a>{" "}
            <a href="/work#danskebank-2017">#3</a>)
          </p>
          <br />

          <h2
            style={{
              textAlign: "center",
              marginBottom: "50px",
              marginTop: "80px",
            }}
          >
            Timeline
          </h2>

          <VerticalTimeline lineColor="#ebebeb">
            {map(work, (item, workKey) => {
              const years = get(item, "years")
              const type =
                get(item, "type") && get(item, "type") === "job"
                  ? "position"
                  : "project"
              return (
                <VerticalTimelineElement
                  key={workKey}
                  className={"vertical-timeline-element--" + type}
                  contentStyle={{ background: "#ebebeb" }}
                  contentArrowStyle={{
                    borderRight: "7px solid #ebebeb",
                  }}
                  date={
                    years.length > 1
                      ? years[0] + " - " + years[years.length - 1]
                      : years[0]
                  }
                  // icon={
                  //   <img
                  //     src={type == "position" ? positionIcon : projectIcon}
                  //   />
                  // }
                  iconStyle={{
                    // background: "rgb(33, 150, 243)",
                    color: "#fff",
                    boxShadow: "0 0 0 4px #ebebeb",
                  }}
                >
                  <h3 className="vertical-timeline-element-title" id={workKey}>
                    <span className={"vertical-timeline-element-title-type"}>
                      {get(item, "type") && get(item, "type") === "job"
                        ? "position: "
                        : "project: "}
                      {/* (
                      {map(get(item, "years"), (year, yearKey) => (
                        <span key={`${workKey}-${year}`}>
                          {year}
                          {get(item, "years").length > 1 &&
                            yearKey !== get(item, "years").length - 1 &&
                            ", "}
                        </span>
                      ))}
                      ): */}
                    </span>
                    {item.title}
                    <span className={"vertical-timeline-element-title-meta"}>
                      {get(item, "co-operators") &&
                        get(item, "co-operators").length > 0 && (
                          <Fragment>
                            <span style={{ color: "rgb(131, 131, 131)" }}>
                              in co-operation with
                            </span>{" "}
                            {get(item, "co-operators")[0]}
                          </Fragment>
                        )}
                      {item.client &&
                        (get(item, "type") && get(item, "type") === "job"
                          ? ` at ` + item.client + " "
                          : ` for ` + item.client + " ")}
                      {item.contractor && (
                        <Fragment>
                          <span style={{ color: "rgb(131, 131, 131)" }}>
                            contracted by{" "}
                          </span>{" "}
                          {item.contractor}
                        </Fragment>
                      )}
                    </span>
                  </h3>
                  {get(item, "links.length") > 0 && (
                    <span className="links">
                      {get(item, "links") &&
                        map(get(item, "links"), (link) => (
                          <Fragment key={`${workKey}-${link}`}>
                            <a
                              href={link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              link
                            </a>
                            &nbsp;
                          </Fragment>
                        ))}
                    </span>
                  )}

                  {item.description && (
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  )}

                  <div className="badges">
                    {/* Type of work:{" "}
                    {map(get(item, "tags"), (tag, tagKey) => (
                      <Fragment key={`${workKey}-${tag}`}>
                        <strong>{tag}</strong>
                        {get(item, "tags").length > 1 &&
                          tagKey !== get(item, "tags").length - 1 &&
                          ", "}
                      </Fragment>
                    ))}
                    <br /> */}
                    Main technologies:{" "}
                    {map(get(item, "technologies"), (technology, techKey) => (
                      <Fragment key={`${workKey}-${techKey}`}>
                        <strong>{technology}</strong>
                        {get(item, "technologies").length > 1 &&
                          techKey !== get(item, "technologies").length - 1 &&
                          ", "}
                      </Fragment>
                    ))}
                    <br />
                    <br />
                    <a href="#top">back to top</a>
                  </div>
                </VerticalTimelineElement>
              )
            })}

            {/* <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "#ebebeb" }}
              contentArrowStyle={{
                borderRight: "7px solid #ebebeb",
              }}
              date="2010 - 2011"
              iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title">Art Director</h3>
              <h4 className="vertical-timeline-element-subtitle">
                San Francisco, CA
              </h4>
              <p>
                Creative Direction, User Experience, Visual Design, SEO, Online
                Marketing
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="2008 - 2010"
              iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title">Web Designer</h3>
              <h4 className="vertical-timeline-element-subtitle">
                Los Angeles, CA
              </h4>
              <p>User Experience, Visual Design</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="2006 - 2008"
              iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title">Web Designer</h3>
              <h4 className="vertical-timeline-element-subtitle">
                San Francisco, CA
              </h4>
              <p>User Experience, Visual Design</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--education"
              date="April 2013"
              iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title">
                Content Marketing for Web, Mobile and Social Media
              </h3>
              <h4 className="vertical-timeline-element-subtitle">
                Online Course
              </h4>
              <p>Strategy, Social Media</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--education"
              date="November 2012"
              iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title">
                Agile Development Scrum Master
              </h3>
              <h4 className="vertical-timeline-element-subtitle">
                Certification
              </h4>
              <p>Creative Direction, User Experience, Visual Design</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--education"
              date="2002 - 2006"
              iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title">
                Bachelor of Science in Interactive Digital Media Visual Imaging
              </h3>
              <h4 className="vertical-timeline-element-subtitle">
                Bachelor Degree
              </h4>
              <p>Creative Direction, Visual Design</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
            /> */}
          </VerticalTimeline>

          {/* <h4>Attributions</h4>
          <strong>Icons Used:</strong>
          <ul>
            <li>Businessman by Cuputo from NounProject.com</li>
            <li>Project by Adrien Coquet from NounProject.com</li>
          </ul> */}
        </Section>
      </Page>
    </>
  )
}
