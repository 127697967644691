import get from 'lodash/get'
import forEach from 'lodash/forEach'
import uniq from 'lodash/uniq'
import workJson from '../../content/sections/work/work.json'

// TODO this should be cached so analyze only happens once per build
export const analyze = () => {
  let work = {}
  let tags = []
  let technologies = []
  let years = []

  forEach(workJson, (item, key) => {
    //console.log(key, item)
    tags = extractArray(tags, item, 'tags')
    technologies = extractArray(technologies, item, 'technologies')
    years = extractArray(years, item, 'years')
    //console.log(get(item, 'tags'))
    //tags.push(item.tags)

    //item.tags = get(item, 'tags').sort()
    item.technologies = get(item, 'technologies').sort()
    if (key !== 'blueprint') {
      work[key] = item
    }
  })
  //console.log(tags)
  //console.log(technologies)
  //console.log(years)
  //console.log(work)

  return {
    work,
    tags,
    technologies,
    years
  }
}

// TODO at a later stage we should count the occurence of each tag
const extractArray = (arr, item, key) => {
  const newArr = uniq(arr.concat(get(item, key))).sort()
  return newArr
}